<template>
  <div>
    <el-input placeholder="请输入内容" v-model="input1">
      <template slot="prepend">原始密码</template>
    </el-input>
    <el-input placeholder="请输入密码" v-model="input" show-password></el-input>
    <el-input placeholder="请输入密码" v-model="input" show-password></el-input>
  </div>
</template>

<script>
export default {
  name: "changePwd"
};
</script>

<style scoped>

</style>
