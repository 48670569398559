<template>
  <div>
    <el-card class="box-card">
      <div class="content" style="display: flex;flex-direction: column">
        <div>
          <h3 style="float:left">余额充值</h3>
        </div>
        <div style="background-color:#B7D8FF">
          <span style="color:grey;margin-left: 20px">余额可用于购买商品或用于商城消费</span>
        </div>
        <div>
          <span style="margin-top: 5px">充值金额：</span>
          <el-button size="small" style="float:left">其他金额</el-button>
        </div>
        <div>
          <span>使用资金：</span>
          <div style="margin: 0px">
            <el-checkbox v-model="checked">是</el-checkbox>
          </div>
          <span style="margin-left: 10px">当前余额</span>
          <span style="color:red">0.00</span>
          <span>元</span>
        </div>
        <div>
        <span>
          还需支付：
        </span>
          <span style="color:red">500.00</span>
          <span>
          元
        </span>
        </div>
        <div>
          <span>支付方式：</span>
          <div style="display:flex;flex-direction: column;margin:0px">
            <div style="margin: 5px">
              <el-radio v-model="radio" label="wechat">微信</el-radio>
              <el-radio v-model="radio" label="alipay">支付宝</el-radio>
            </div>

          </div>
          <el-divider></el-divider>
          <div>
            <el-checkbox v-model="checked2">我已阅读并通知充值购买服务协议</el-checkbox>
          </div>
          <br />
          <div>
            <el-button type="primary">立即充值</el-button>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "inputMoney",
  data() {
    return {
      checked: false,
      checked2: false,
      radio: "1"
    };
  }
};
</script>

<style scoped>
.content div {
  margin: 20px 0 0 20px;
}

.content div span {
  float: left;
}

.content div div {
  float: left;
}
</style>
