<template>
  <div style="width: auto;height:100%;background-color:#F3F7FF">
    <div id="page1">
      <div id="top">
        <div class="top-span">
          <div class="box1 flex-row" style="margin:0 0 0 400px">
            <div class="wrap1 flex-col">
              <div class="group2 flex-col"></div>
            </div>
            <span style="font-size:24px;margin:5px 0 0 10px">皮特宠物</span>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;">
      <div style="margin:150px 0 0 400px;display: flex;">
        <div>
          <img src="../../assets/img/login/backgroundImg.png" alt="" style="width:589px;height:600px">
        </div>
        <div
          style="width:400px;height:400px;background-color: white;margin:100px 0 0 180px;display: flex;flex-direction: column;">
          <h3 style="float:left;margin:10px 0 0 0px">账号密码登录</h3>
          <el-input v-model="userName" placeholder="用户名" size="medium"
                    style="width:300px;margin: 40px 0 0 40px"></el-input>
          <el-input v-model="password" placeholder="密码" size="medium" style="width:300px;margin: 40px 0 0 40px"
                    show-password></el-input>
          <div>
            <span style="float:right;margin:10px 50px 0 0;font-size: 13px;">忘记密码？</span>
          </div>
          <el-button type="primary" style="width:300px;margin:20px 0 0 40px" @click="login('login')"
                     :loading="btnLoading">登录
          </el-button>
          <div style="margin:20px 0 0 0;font-size: 13px;">
            <el-link :underline="false" href="/registerUser">免费注册</el-link>
            <el-link :underline="false" style="margin-left:80px" @click="login('down')">资源下载</el-link>
          </div>
        </div>
      </div>
    </div>
    <div>
      <span style="color:#FF5C5C5C;font-size:12px">copyright&nbsp;&nbsp;2017-2020</span>
      <span style="color:#FF5C5C5C;font-size:12px;margin:0 0 0 30px">麦蒂宠物用品采购商城&nbsp;&nbsp;All&nbsp;&nbsp;Rights&nbsp;&nbsp;Rved&nbsp;&nbsp;蜀ICP2021026870号</span>
    </div>

    <el-dialog
      title="支付"
      :visible="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div>
        <el-radio-group v-model="payMethod" @change="getPayUrl">
          <el-radio label="wechat">微信</el-radio>
          <el-radio label="alipay">支付宝</el-radio>
        </el-radio-group>

      </div>
      <!--      <vue-qr logoSrc="../../../public/favicon.ico" text="https://blog.csdn.net/weixin_42601136" :size="200"></vue-qr>-->
      <vue-qr :text=payWxURL :size="200"></vue-qr>
      <br>
      <span style="font-size:16px;color:red">手机显示付款成功请点击确认,并联系对应工作人员!</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import vueQr from "vue-qr";

export default {
  name: "index",
  components: {
    vueQr
  },
  data() {
    return {
      userName: "",
      password: "",
      btnLoading: false,
      dialogVisible: false,
      payWxURL: "",
      payMethod: "wechat"
    };
  },
  methods: {
    login(op) {
      this.btnLoading = true;
      this.$axios.post("https://index.myhemall.com:12120/api/login", {
        username: this.userName,
        password: this.password
      }).then((res) => {
        if (res.data.errno == 0) {
          localStorage.setItem("userId", res.data.data.id);
          if (res.data.data.pay_status === 0) {
            this.dialogVisible = true;
            this.getPayUrl("wechat");
          } else {
            if (op === "login") {
              window.open(res.data.data.console, "_self");
            } else if (op === "down") {
              this.$router.push("/goods/index");
              this.$store.commit("setUserName", res.data.data.username);
            }
          }
          localStorage.setItem("user", JSON.stringify(res.data.data));
          // this.$router.push("/user/info");
        } else {
          if (res.data.errmsg === "record not found") {
            this.$message.error("账号或密码不对");
          } else {
            this.$message.error("未知错误,请联系管理员");
          }
        }
        this.btnLoading = false;
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        // eslint-disable-next-line no-unused-vars
        .then(_ => {
          done();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(_ => {
        });
    },
    getPayUrl(payMethod) {
      let id = localStorage.getItem("userId");
      switch (payMethod) {
        case "wechat":
          this.getWxPayUrl(id);
          break;
        case "alipay":
          this.getAlipayUrl(id);
          break;
      }
    },
    getWxPayUrl(id) {
      //https://index.myhemall.com:12120
      this.$axios.get("https://index.myhemall.com:12120/pay/wechat?id=" + id).then((res) => {
        if (res.data.errno == 0) {
          this.payWxURL = res.data.data.url;
          console.log(this.payWxURL);
        } else {
          this.$message.error(res.data.errmsg);
        }
      });
    },
    getAlipayUrl(id) {
      this.$axios.get("https://index.myhemall.com:12120/pay/alipay?id=" + id).then((res) => {
        if (res.data.errno == 0) {
          this.url = res.data.data.url;
          window.open(this.url);
        } else {
          if (res.data.errmsg === "record not found") {
            this.$message.error("账号或密码错误");
          }
        }
      });
    }
  }
};
</script>

<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/shop.css";

#top {
  position: absolute;
  margin: 10px 0 0 0;
}

#info1 {
  font-size: 24px;
  color: black;
}

</style>
