<template>
  <div>
    <el-card class="box-card">

      <div style="display: flex;flex-direction: column">
        <h2>余额日志</h2>
        <div style="background-color:darkblue;width:100%;height:200px;">
          <div style="display: flex;flex-direction: column">
            <div>
              <span style="color:white;font-size:36px;float:left;margin: 30px 0 0 30px">商店中心</span>
            </div>
            <div style="margin: 10px 0 0 30px;color:white">
              <span style="float:left">新购&续费</span>
            </div>
            <el-button round style="background: transparent;color:white;width:150px;margin: 10px 0 0 30px;"
                       icon="el-icon-star-on">立即前往
            </el-button>
          </div>
        </div>
        <div style="background-color:cornflowerblue;width:100%;height:200px;margin-top:10px">
          <div style="display: flex;flex-direction: column">
            <div>
              <div style="color:white;font-size:36px;float:left;margin: 30px 0 0 30px">
                <span>￥</span>
                <span>{{ userInfo.money }}</span>
              </div>
            </div>
            <div style="margin: 10px 0 0 30px;color:white">
              <span style="float:left">温馨提示：用户购买购买商品以及分佣、充值余额均在这里显示，使用以及购买过后均在这里抵扣，如给您带来不变，敬请谅解释</span>
            </div>
            <el-button round style="background: transparent;color:white;width:150px;margin: 10px 0 0 30px;"
                       icon="el-icon-s-promotion" @click="jumpToURL">立即购买
            </el-button>
          </div>
        </div>
        <el-card class="box-card" style="margin-top:10px">
          <div>余额记录(只显示最近半年记录)</div>
          <el-table
            :data="tableData"
            border
            size="mini"
            style="width: 100%">
            <el-table-column
              prop="memo"
              width="300"
              label="变动描述">
            </el-table-column>
            <el-table-column
              prop="before"
              label="更变之前">
            </el-table-column>
            <el-table-column
              prop="money"
              label="更变价格">
            </el-table-column>
            <el-table-column
              prop="after"
              label="更变之后">
            </el-table-column>
            <el-table-column
              prop="createtime"
              label="时间">
              <template slot-scope="scope">
                {{ scope | formatData }}
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "moneyLog",
  data() {
    return {
      tableData: [],
      userInfo: {}
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("user"));
    this.moneyLog();
  },
  methods: {
    moneyLog() {
      this.$axios.get("https://index.myhemall.com:12120/api/user/userMoneyLog?id=" + this.userInfo.id).then((res) => {
        this.tableData = res.data.data.list;
      });
    },
    jumpToURL(){
      this.$router.push("/user/inputMoney")
    }
  },
  filters: {
    formatData(value) {
      return moment(value).format("YYYY-MM-DD");
    }
  }
};
</script>

<style scoped>

</style>
