<template>
  <div style="width: auto;height:100%;background-color:#F3F7FF">
    <div id="page1">
      <div id="top">
        <div class="top-span">
          <div class="box1 flex-row" style="margin:0 0 0 400px">
            <div class="wrap1 flex-col">
              <div class="group2 flex-col"></div>
            </div>
            <span style="font-size:24px;margin:5px 0 0 10px">皮特宠物</span>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;">
      <div style="margin:150px 0 0 400px;display: flex;">
        <div>
          <img src="../../assets/img/login/backgroundImg.png" alt="" style="width:589px;height:600px">
        </div>
        <div
          style="width:400px;height:570px;background-color: white;margin:50px 0 0 180px;display: flex;flex-direction: column;">
          <h3 style="float:left;margin:10px 0 0 0">会&nbsp;员&nbsp;注&nbsp;册</h3>
          <el-input class="inputText" v-model="email" placeholder="" size="medium">
            <template slot="prepend">邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱</template>
          </el-input>
          <el-input class="inputText" v-model="userName" placeholder="" size="medium">
            <template slot="prepend">用&nbsp;户&nbsp;&nbsp;&nbsp;名</template>
          </el-input>
          <el-input class="inputText" v-model="password" placeholder="" size="medium" show-password>
            <template slot="prepend">密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码</template>
          </el-input>
          <el-input class="inputText" v-model="confirmPassword" placeholder="" size="medium" show-password>
            <template slot="prepend">确认密码</template>
          </el-input>
          <el-input class="inputText" v-model="phone" placeholder="" size="medium">
            <template slot="prepend">手&nbsp;机&nbsp;&nbsp;号</template>
          </el-input>
          <el-input class="inputText" v-model="code" placeholder="" size="medium">
            <template slot="prepend">验&nbsp;&nbsp;证&nbsp;码</template>
            <el-button slot="append" @click="checkMobile" :disabled="btnDisabled">发送验证({{ timeout }}s)</el-button>
          </el-input>
          <el-button type="primary" style="width:300px;margin:20px 0 0 40px" @click="register">注册</el-button>
          <div style="margin:10px 20px 0 0;font-size: 13px;">
            <el-link :underline="false" href="/login">登录</el-link>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 40px">
      <span style="color:#FF5C5C5C;font-size:12px">copyright&nbsp;&nbsp;2017-2020</span>
      <span style="color:#FF5C5C5C;font-size:12px;margin:0 0 0 30px">麦蒂宠物用品采购商城&nbsp;&nbsp;All&nbsp;&nbsp;Rights&nbsp;&nbsp;Rved&nbsp;&nbsp;蜀ICP2021026870号</span>
    </div>

    <el-dialog
      title="支付"
      :visible="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div>
        <el-radio-group v-model="payMethod" @change="getPayUrl">
          <el-radio label="wechat">微信</el-radio>
          <el-radio label="alipay">支付宝</el-radio>
        </el-radio-group>

      </div>
      <!--      <vue-qr logoSrc="../../../public/favicon.ico" text="https://blog.csdn.net/weixin_42601136" :size="200"></vue-qr>-->
      <vue-qr :text=payWxURL :size="200"></vue-qr>
      <br>
      <span style="font-size:16px;color:red">手机显示付款成功请点击确认,并联系对应工作人员!</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import vueQr from "vue-qr";

export default {
  name: "registerUser",
  components: {
    vueQr
  },
  data() {
    return {
      dialogVisible: false,
      userName: "",
      password: "",
      email: "",
      code: "",
      phone: "",
      confirmPassword: "",
      payWxURL: "",
      payMethod: "wechat",
      timeout: 30,
      btnDisabled: false
    };
  },
  methods: {
    register() {
      this.$axios.post("https://index.myhemall.com:12120/api/user/register", {
        email: this.email,
        username: this.userName,
        password: this.password,
        confirmPassword: this.confirmPassword,
        phoneNumber: this.phone,
        code: this.code
      }).then((res) => {
        if (res.data.errno == 0) {
          alert("注册成功");
          this.dialogVisible = true;
          localStorage.setItem("userId", res.data.data.id);
          this.getPayUrl("wechat");
        } else {
          if (res.data.errmsg === "record not found") {
            this.$message.error("账号或密码错误");
          } else {
            this.$message.error("未知错误,请联系管理员");
          }

        }
      });
    },
    getPayUrl(payMethod) {
      let id = localStorage.getItem("userId");
      switch (payMethod) {
        case "wechat":
          this.getWxPayUrl(id);
          break;
        case "alipay":
          this.getAlipayUrl(id);
          break;
      }
    },
    getWxPayUrl(id) {
      //https://index.myhemall.com:12120
      this.$axios.get("https://index.myhemall.com:12120/pay/wechat?id=" + id).then((res) => {
        if (res.data.errno == 0) {
          this.payWxURL = res.data.data.url;
          console.log(this.payWxURL);
        } else {
          this.$message.error(res.data.errmsg);
        }
      });
    },
    getAlipayUrl(id) {
      this.$axios.get("https://index.myhemall.com:12120/pay/alipay?id=" + id).then((res) => {
        if (res.data.errno == 0) {
          this.url = res.data.data.url;
          window.open(this.url);
        } else {
          this.$message.error(res.data.errmsg);
        }
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        // eslint-disable-next-line no-unused-vars
        .then(_ => {
          done();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(_ => {
        });
    },
    checkMobile() {
      this.btnDisabled = true;
      const id = setInterval(() => {
        this.timeout--;
        if (this.timeout === 0) {
          this.btnDisabled = false;
          clearInterval(id);
          this.timeout = 30;
        }
      }, 1000);
      //https://www.myhemall.com/api/sms/send.html
      this.$axios.get("https://index.myhemall.com:12120/api/user/smsCode?phoneNumber=" + this.phone).then((res) => {
        if (res.data.errno === 2001) {
          this.$message.error(res.data.errmsg);
        } else {
          this.$message.success('验证码发送成功');
        }
      });
    }
  }
};
</script>

<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/shop.css";

#top {
  position: absolute;
  margin: 10px 0 0 0;
}

#info1 {
  font-size: 24px;
  color: black;
}

.inputText {
  width: 300px;
  margin: 30px 0 0 40px
}

</style>
