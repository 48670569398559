<template>
  <div style="width: 1280px;">
    <div id="page1">
      <div id="top">
        <div class="top-span">
          <div class="box1 flex-row">
            <div class="wrap1 flex-col">
              <div class="group2 flex-col"></div>
            </div>
            <span class="info1">皮特宠物</span>
            <el-link :underline="false" class="txt1" type="color:white" href="/">首页</el-link>
            <el-link :underline="false" class="word2" type="color:white" href="/distribution">分销</el-link>
            <el-link :underline="false" class="txt1" type="color:white" href="/register">会员</el-link>
            <el-link :underline="false" class="txt1" type="color:white" href="/shop">商城</el-link>
            <el-link :underline="false" class="word2" type="color:white" href="/login">登录</el-link>
            <div class="wrap2 flex-col">
              <span class="txt2">免费试用</span>
            </div>
          </div>
        </div>
      </div>
      <img id="title-img" src="../../assets/img/distribution/title.png" style="width: 1280px;" alt="">
    </div>
    <div>
      <h1 style="font-family: SourceHanSansCN-Regular;">各大优势值得选择</h1>
    </div>
    <div id="contet" style="">
      <div style="display:flex;flex-wrap: wrap;width: 1080px;font-size: 14px;">
        <div style="display: flex;flex-wrap: wrap;width: 1080px;    margin:60px 0 0 50px;">
          <div>
            <div style="height: 92px;">
              <img src="../../assets/img/distribution/transport.png" alt="">
            </div>
            <h3>商品快速流通</h3>
            <div style="width: 160px;">
              <span style="width: 160px;">商品管理、厂家直销、仓储体系、物流跟踪等一站式处理</span>
            </div>
          </div>
          <div>
            <div>
              <img src="../../assets/img/distribution/money.png" alt="">
            </div>
            <h3>价格管控</h3>
            <div style="width: 160px;">
              <span style="width: 160px;">控制商品成本、限定零售价范围、保证市场品牌价值</span>
            </div>
          </div>
          <div>
            <div>
              <img src="../../assets/img/distribution/money2.png" alt="">
            </div>
            <h3>独立小程序</h3>
            <div style="width: 160px;">
              <span style="width: 160px;">所有小程序自行无需操作和负责，0风险、0费用，一键式销售</span>
            </div>
          </div>
          <div>
            <div>
              <img src="../../assets/img/distribution/shop.png" alt="">
            </div>
            <h3>个性化销售</h3>
            <div style="width: 160px;">
              <span style="width: 160px;">商品管理、厂家直销、仓储体系、物流跟踪等一站式处理</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 1280px;height: 500px;background-color: #DAEAFF;">
      &nbsp;

      <h1>赋予分销商全链运营 促进客户转化</h1>

      &nbsp;
      &nbsp;
      <div style="display: flex;justify-content: center;">
        <div style="margin: 0 10px 0 10px;">
          <div
            style=" background-image:linear-gradient(to right,#1540BB, #3F70FC);;height: 100px;width: 219px;display: flex;">
            <img src="../../assets/img/distribution/user.png" alt=""
                 style="width: 50px;height: 40px;margin: 30px 0 0 30px;">
            <h3 style="margin: 35px 0 0 20px; color: #FFFFFF;">全域获客</h3>
          </div>
          <div style="height: 200px;background-color: #FFFFFF;">
            <div style="height: 25px;"></div>
            <div>
              <span style="color: blue;">√</span>
              <span style="margin: 0 0 0 10px;">小程序获客</span>
            </div>
            <div style="height: 25px;"></div>
            <div>
              <span style="color: blue;">√</span>
              <span style="margin: 0 0 0 10px;">平台获客&nbsp;&nbsp;&nbsp;</span>
            </div>
            <div style="height: 25px;"></div>
            <div>
              <span style="color: blue;">√</span>
              <span style="margin: 0 0 0 10px;">裂变获客&nbsp;&nbsp;&nbsp;</span>
            </div>
          </div>
        </div>
        <div style="margin: 0 10px 0 10px;">
          <div
            style=" background-image:linear-gradient(to right,#1540BB, #3F70FC);height: 100px;width: 219px;display: flex;">
            <img src="../../assets/img/distribution/for.png" alt=""
                 style="width: 50px;height: 40px;margin: 30px 0 0 30px;">
            <h3 style="margin: 35px 0 0 20px; color: #FFFFFF;">运营管理</h3>
          </div>
          <div style="height: 200px;background-color: #FFFFFF;">
            <div style="height: 25px;"></div>
            <div>
              <span style="color: blue;">√</span>
              <span style="margin: 0 0 0 10px;">引流截流</span>
            </div>
            <div style="height: 25px;"></div>
            <div>
              <span style="color: blue;">√</span>
              <span style="margin: 0 0 0 10px;">数据分析</span>
            </div>
            <div style="height: 25px;"></div>
            <div>
              <span style="color: blue;">√</span>
              <span style="margin: 0 0 0 10px;">异常提醒</span>
            </div>
          </div>
        </div>
        <div style="margin: 0 10px 0 10px;">
          <div
            style=" background-image:linear-gradient(to right,#1540BB, #3F70FC);;height: 100px;width: 219px;display: flex;">
            <img src="../../assets/img/distribution/distribution.png" alt=""
                 style="width: 50px;height: 40px;margin: 30px 0 0 30px;">
            <h3 style="margin: 35px 0 0 20px; color: #FFFFFF;">渠道分销</h3>
          </div>
          <div style="height: 200px;background-color: #FFFFFF;">
            <div style="height: 25px;"></div>
            <div>
              <span style="color: blue;">√</span>
              <span style="margin: 0 0 0 10px;">供应商入驻</span>
            </div>
            <div style="height: 25px;"></div>
            <div>
              <span style="color: blue;">√</span>
              <span style="margin: 0 0 0 10px;">导购分销&nbsp;&nbsp;&nbsp;</span>
            </div>
            <div style="height: 25px;"></div>
            <div>
              <span style="color: blue;">√</span>
              <span style="margin: 0 0 0 10px;">直播运营&nbsp;&nbsp;&nbsp;</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 370px;width: 1070px;">
      <div style="margin-top: 20px;">
        <h1>已入驻品牌</h1>
      </div>
      <div>
        <img src="../../assets/img/distribution/cooperative-partner.png" alt=""
             style="width: 900px;height: 300px;">
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import Foot from "@/components/foot";
export default {
  name: "distribution",
  components: {
    Foot,
  }
};
</script>

<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/index.css";

#top {
  position: absolute;
  margin: 10px 0 0 0;
}

#contet {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#contet div div {
  margin: 20px;
  font-size: 13px;
}


#contet div h3 {
  margin-top: 60px;
}
</style>
