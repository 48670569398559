<template>
  <div style="width: 1280px;">
    <div style="width: 1280px;">
      <div id="page1">
        <div id="top">
          <div class="top-span">
            <div class="box1 flex-row">
              <div class="wrap1 flex-col">
                <div class="group2 flex-col"></div>
              </div>
              <span class="info1">皮特宠物</span>
              <el-link :underline="false" class="txt1" type="color:white" href="/">首页</el-link>
              <el-link :underline="false" class="word2" type="color:white" href="/distribution">分销</el-link>
              <el-link :underline="false" class="txt1" type="color:white" href="/register">会员</el-link>
              <el-link :underline="false" class="txt1" type="color:white" href="/shop">商城</el-link>
              <el-link :underline="false" class="word2" type="color:white" href="/login">登录</el-link>
              <div class="wrap2 flex-col">
                <span class="txt2">免费试用</span>
              </div>
            </div>
          </div>
        </div>
        <img id="title-img" src="../../assets/img/register/title.png" style="width: 1280px;" alt="">
      </div>
    </div>
    <div>
      <h1 style="font-family: SourceHanSansCN-Regular;">皮特能为你做什么</h1>
    </div>
    <div id="contet" style="">
      <div style="display:flex;flex-wrap: wrap;width: 970px;font-size: 14px;">
        <div>
          <div style="width: 17%;">
            <img src="../../assets/img/register/transport.png" alt=""
                 style="width: 50px;height: 50px;margin: 33px 0 0 10px;">
          </div>
          <div style="width: 200px;display: flex;flex-wrap: wrap;">
            <div style="height: 20px;">
              <h3 style="font-size: 18px;">物流系统</h3>
            </div>
            <div>
              <p style="width: 180px;">先进完善的物流体系，确保货源的及时运输。</p>
            </div>
          </div>
        </div>
        <div>
          <div style="width: 17%;">
            <img src="../../assets/img/register/warehouse.png" alt=""
                 style="width: 50px;height: 50px;margin: 33px 0 0 10px;">
          </div>
          <div style="width: 200px;display: flex;flex-wrap: wrap;">
            <div style="height: 20px;">
              <h3>仓储系统</h3>
            </div>
            <div>
              <p style="width: 180px;">自有的仓库、仓储一体化流程，确保商品储存和运输。</p>
            </div>
          </div>
        </div>
        <div>
          <div style="width: 17%;">
            <img src="../../assets/img/register/shop.png" alt=""
                 style="width: 50px;height: 50px;margin: 33px 0 0 10px;">
          </div>
          <div style="width: 200px;display: flex;flex-wrap: wrap;">
            <div style="height: 20px;">
              <h3>店铺管理</h3>
            </div>
            <div>
              <p style="width: 180px;">全托管模式、自行管理模式实现店铺灵活运行。</p>
            </div>
          </div>
        </div>
        <div>
          <div style="width: 17%;">
            <img src="../../assets/img/register/price-management.png" alt=""
                 style="width: 50px;height: 50px;margin: 33px 0 0 10px;">
          </div>
          <div style="width: 200px;display: flex;flex-wrap: wrap;">
            <div style="height: 20px;">
              <h3>价格管控</h3>
            </div>
            <div>
              <p style="width: 180px;">控制商品成本、限定商品零售价格、保证市场品牌价值</p>
            </div>
          </div>
        </div>
        <div>
          <div style="width: 17%;">
            <img src="../../assets/img/register/chat.png" alt=""
                 style="width: 50px;height: 50px;margin: 33px 0 0 10px;">
          </div>
          <div style="width: 200px;display: flex;flex-wrap: wrap;">
            <div style="height: 20px;">
              <h3>售前服务</h3>
            </div>
            <div>
              <p style="width: 180px;">售前咨询团队将竭诚为您提供产品咨询，方案解决及其他售前服务。</p>
            </div>
          </div>
        </div>
        <div>
          <div style="width: 17%;">
            <img src="../../assets/img/register/customer-service.png" alt=""
                 style="width: 50px;height: 50px;margin: 33px 0 0 10px;">
          </div>
          <div style="width: 200px;display: flex;flex-wrap: wrap;">
            <div style="height: 20px;">
              <h3>售后服务</h3>
            </div>
            <div>
              <p style="width: 180px;">专业技术团队将7×24小时待命，充分保障您产品的试用及店铺的稳定运行。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 370px;width: 1070px;">
      <div style="margin-top: 20px;">
        <h1>会员权益</h1>
      </div>
      <div style="display: flex;justify-content: space-around">
        <div>
          <img src="../../assets/img/register/log1.png" alt="">
          <h2>会员自有商城</h2>
        </div>
        <div>
          <img src="../../assets/img/register/log2.png" alt="">
          <h2>私域运营服务</h2>
        </div>
        <div>
          <img src="../../assets/img/register/log3.png" alt="">
          <h2>渠道经营扶持</h2>
        </div>
      </div>
    </div>
    <div style="background-color: #1540BB;height: 200px;">
      &nbsp;
      <h1 style="color: #FFFFFF;">立即注册 , 开启你得分销成长之旅！</h1>
      &nbsp;
      <div
        style="background-color: #0071EF;width: 101px;height: 40px;display: flex;justify-content: center;">
        <span style="margin: 8px;color: #FFFFFF;">立即注册</span>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import Foot from "@/components/foot";
export default {
  name: "register",
  components: {
    Foot,
  }
};
</script>

<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/index.css";
#contet {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#contet div div {
  width: 300px;
  height: 150px;
  margin: 10px;
  display: flex;
}


.foolt-div div {
  margin: 10px 0 0 50px;
}
#top {
  position: absolute;
  margin: 10px 0 0 0;
}
</style>
