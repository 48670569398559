import { render, staticRenderFns } from "./moneyLog.vue?vue&type=template&id=2a87f099&scoped=true&"
import script from "./moneyLog.vue?vue&type=script&lang=js&"
export * from "./moneyLog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a87f099",
  null
  
)

export default component.exports