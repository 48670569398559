<template>
  <div style="width: 1280px;">
    <div id="page1">
      <div id="top">
        <div class="top-span">
          <div class="box1 flex-row">
            <div class="wrap1 flex-col">
              <div class="group2 flex-col"></div>
            </div>
            <span class="info1">皮特宠物</span>
            <el-link :underline="false" class="txt1" type="color:white" href="/">首页</el-link>
            <el-link :underline="false" class="word2" type="color:white" href="/distribution">分销</el-link>
            <el-link :underline="false" class="txt1" type="color:white" href="/register">会员</el-link>
            <el-link :underline="false" class="txt1" type="color:white" href="/shop">商城</el-link>
            <el-link :underline="false" class="word2" type="color:white" href="/login">登录</el-link>
            <div class="wrap2 flex-col">
              <span class="txt2">免费试用</span>
            </div>
          </div>
        </div>
      </div>
      <img id="title-img" src="../../assets/img/shop/title.png" style="width: 1280px;" alt="">
    </div>
    <div>
      <h1 style="font-family: SourceHanSansCN-Regular;">功能强大的小程序商城</h1>
    </div>
    <div id="content" style="">
      <div class="wrap4 ">
        <div style="width: 30%;">
          <img src="../../assets/img/shop/number-icon.png" alt="" style="margin-bottom: 13px;">
        </div>
        <div style="width: 69%;display: flex;flex-wrap: wrap; margin-bottom: 30px;">
          <div style="width: 100%;">
            <h3 style="float: left;">全域会员精细运营</h3>
          </div>
          <div style="width: 100%;">
            <span style="color: #585858;font-size: 13px;">全域会员多渠道统一管理，客户引流截流裂变并标签分层，沉淀会员资产。</span>
          </div>
        </div>
      </div>
      <div class="wrap4 ">
        <div style="width: 30%;">
          <img src="../../assets/img/shop/shop-icon.png" alt="" style="margin-bottom: 13px;">
        </div>
        <div style="width: 69%;display: flex;flex-wrap: wrap; margin-bottom: 30px;">
          <div style="width: 100%;">
            <h3 style="float: left;">店铺灵活性管理</h3>
          </div>
          <div style="width: 100%;">
            <span style="color: #585858;font-size: 13px;">全域会员多渠道统一管理，客户引流截流裂变并标签分层，沉淀会员资产。</span>
          </div>
        </div>
      </div>
      <div class="wrap4 ">
        <div style="width: 30%;">
          <img src="../../assets/img/shop/distribution-icon.png" alt="" style="margin-bottom: 13px;">
        </div>
        <div style="width: 69%;display: flex;flex-wrap: wrap; margin-bottom: 30px;">
          <div style="width: 100%;">
            <h3 style="float: left;">快速裂变分销</h3>
          </div>
          <div style="width: 100%;">
            <span style="color: #585858;font-size: 13px;">裂变分销促进商家快速获客，拓展销售渠道，促进商家业绩快速增长</span>
          </div>
        </div>
      </div>
      <div class="wrap4 ">
        <div style="width: 30%;">
          <img src="../../assets/img/shop/profit.png" alt="" style="margin-bottom: 13px;">
        </div>
        <div style="width: 69%;display: flex;flex-wrap: wrap; margin-bottom: 30px;">
          <div style="width: 100%;">
            <h3 style="float: left;">数据分析提高转化率</h3>
          </div>
          <div style="width: 100%;">
            <span style="color: #585858;font-size: 13px;">全方位分析店铺各项数据，了解弊端并协助解决，提升店铺转化率。</span>
          </div>
        </div>
      </div>
    </div>
    <div style="background-color: #B7D8FF;width:1280px;height: 500px;margin-top: 50px;">
      &nbsp;
      <div style="margin-top: 10px;">
        <h1>了解自有商城，玩转店铺运营</h1>
      </div>
      <div style="display: flex;justify-content: center;">
        <div class="wrap5">
          <img src="../../assets/img/shop/user.png" alt="" style="margin-top:30px;">
          <h3>小程序独立使用</h3>
          <div class="bd4 flex-col" style="width: 80%;"></div>
          <div style="margin-top: 20px;">
            <span style="color: #0971FF;">√</span>
            <span style="margin-left: 10px;color: #646464;">用户信息注册&nbsp;&nbsp;&nbsp;</span>
          </div>
          <div style="margin-top: 20px;">
            <span style="color: #0971FF;">√</span>
            <span style="margin-left: 10px;color: #646464;">绑定收款账号&nbsp;&nbsp;&nbsp;</span>
          </div>
          <div style="margin-top: 20px;">
            <span style="color: #0971FF;">√</span>
            <span style="margin-left: 10px;color: #646464;">销售额直接到账</span>
          </div>
        </div>
        <div class="wrap5">
          <img src="../../assets/img/shop/number-icon2.png" alt="" style="margin-top:30px;">
          <h3>会员独立管理</h3>
          <div class="bd4 flex-col" style="width: 80%;"></div>
          <div style="margin-top: 20px;">
            <span style="color: #0971FF;">√</span>
            <span style="margin-left: 10px;color: #646464;">会员等级设计&nbsp;&nbsp;&nbsp;</span>
          </div>
          <div style="margin-top: 20px;">
            <span style="color: #0971FF;">√</span>
            <span style="margin-left: 10px;color: #646464;">会员折扣设置&nbsp;&nbsp;&nbsp;</span>
          </div>
          <div style="margin-top: 20px;">
            <span style="color: #0971FF;">√</span>
            <span style="margin-left: 10px;color: #646464;">会员信息0泄露</span>
          </div>
        </div>
        <div class="wrap5">
          <img src="../../assets/img/shop/number-icon2.png" alt="" style="margin-top:30px;">
          <h3>小程序独立装修</h3>
          <div class="bd4 flex-col" style="width: 80%;"></div>
          <div style="margin-top: 20px;">
            <span style="color: #0971FF;">√</span>
            <span style="margin-left: 10px;color: #646464;">独立商城后台&nbsp;&nbsp;&nbsp;</span>
          </div>
          <div style="margin-top: 20px;">
            <span style="color: #0971FF;">√</span>
            <span style="margin-left: 10px;color: #646464;">个性化销售服务</span>
          </div>
          <div style="margin-top: 20px;">
            <span style="color: #0971FF;">√</span>
            <span style="margin-left: 10px;color: #646464;">个性化商城装修</span>
          </div>
        </div>
        <div class="wrap5">
          <img src="../../assets/img/shop/brand.png" alt="" style="margin-top:30px;">
          <h3>个性化销售</h3>
          <div class="bd4 flex-col" style="width: 80%;"></div>
          <div style="margin-top: 20px;">
            <span style="color: #0971FF;">√</span>
            <span style="margin-left: 10px;color: #646464;">个体设定价格&nbsp;&nbsp;&nbsp;</span>
          </div>
          <div style="margin-top: 20px;">
            <span style="color: #0971FF;">√</span>
            <span style="margin-left: 10px;color: #646464;">个体设定活动&nbsp;&nbsp;&nbsp;</span>
          </div>
          <div style="margin-top: 20px;">
            <span style="color: #0971FF;">√</span>
            <span style="margin-left: 10px;color: #646464;">个性化定制销售</span>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 300px;display: flex;">
      <div style="width: 50%;">
        <img src="../../assets/img/shop/analysis.png" alt=""
             style="width: 338px;height: 139px;margin: 81px 81px 81px 240px;">
      </div>
      <div style="width: 25%;display: flex;flex-wrap: wrap;">
        <div style="height: 45px;">
          <h2 style="margin-top: 75px;">全域获客引进新生流量</h2>
        </div>
        <div style="width: 400px;height: 0px;font-size: 13px;">
          <span style="float: left;">支持多平台、多方位社区平台，多种运营功能可实现多种客户、多种场景的个性化运营！</span>
        </div>
        <div style="width: 400px;height: 40px;display: flex;">
          <div
            style="background-color: #0071EF;width: 101px;height: 40px;display: flex;justify-content: center;">
            <span style="margin: 8px;color: #FFFFFF;">立即咨询</span>
          </div>
          <div
            style="background-color: #DCEAF9;width: 101px;height: 40px;display: flex;justify-content: center;margin-left: 50px;">
            <span style="margin: 8px;color: #FFFFFF;">申请使用</span>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 300px;display: flex;">
      <div style="width: 45%;display: flex;flex-wrap: wrap;margin-left: 240px;">
        <div style="height: 45px;">
          <h2 style="margin-top: 75px;">多元化管理持续沉淀客户流量</h2>
        </div>
        <div style="width: 400px;height: 0px;font-size: 13px;">
          <span style="float: left;">灵活性、多元化、数据化管理，多种营销玩法，刺激用户再次消费，持续沉淀客户流量。</span>
        </div>
        <div style="width: 400px;height: 40px;display: flex;">
          <div
            style="background-color: #0071EF;width: 101px;height: 40px;display: flex;justify-content: center;">
            <span style="margin: 8px;color: #FFFFFF;">立即咨询</span>
          </div>
          <div
            style="background-color: #DCEAF9;width: 101px;height: 40px;display: flex;justify-content: center;margin-left: 50px;">
            <span style="margin: 8px;color: #FFFFFF;">申请使用</span>
          </div>
        </div>
      </div>
      <div style="width: 25%;">
        <img src="../../assets/img/shop/crm-icon.png" alt=""
             style="width: 234px;height: 220px;margin: 81px 81px 81px -184px;">
      </div>
    </div>
    <div style="height: 415px;display: flex;">
      <div style="width: 50%;">
        <img src="../../assets/img/shop/show.png" alt=""
             style="width: 338px;height: 310px;margin: 81px 81px 81px 240px;">
      </div>
      <div style="width: 25%;display: flex;flex-wrap: wrap;">
        <div style="height: 45px;">
          <h2 style="margin-top: 75px;">分销裂变提高销量</h2>
        </div>
        <div style="width: 400px;height: 0px;font-size: 13px;">
          <span style="float: left;">构建完整的分销体系，佣金奖励、等级刺激、功能灵活，全面满足商家全场景营销需求！</span>
        </div>
        <div style="width: 400px;height: 40px;display: flex;">
          <div
            style="background-color: #0071EF;width: 101px;height: 40px;display: flex;justify-content: center;">
            <span style="margin: 8px;color: #FFFFFF;">立即咨询</span>
          </div>
          <div
            style="background-color: #DCEAF9;width: 101px;height: 40px;display: flex;justify-content: center;margin-left: 50px;">
            <span style="margin: 8px;color: #FFFFFF;">申请使用</span>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 300px;">
      <div style="margin-top: 20px;">
        <h1>他们都在使用皮特</h1>
        <img src="../../assets/img/shop/cooperative-partner.png" alt="" style="width: 870px;">
      </div>
    </div>
    <div style="background-color: #1540BB;height: 200px;">
      &nbsp;
      <h1 style="color: #FFFFFF;">立即注册 , 开启你得分销成长之旅！</h1>
      &nbsp;
      <div
        style="background-color: #0071EF;width: 101px;height: 40px;display: flex;justify-content: center;">
        <span style="margin: 8px;color: #FFFFFF;">立即注册</span>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import Foot from "@/components/foot";

export default {
  name: "shop",
  components: {
    Foot,
  }
};
</script>

<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/shop.css";

#top {
  position: absolute;
  margin: 10px 0 0 0;
}

#content {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
