<template>
  <div style="background-color:#F2F7F8;height:100%">
    <div style="width:1280px">
      <el-container>
        <el-header style="background-color:white">
          <div id="page1">
            <div id="top">
              <div class="top-span" style="display: flex">
                <div class="box1 flex-row" style="margin:0 200px 0 0 ;width:840px" >
                  <div class="wrap1 flex-col">
                    <div class="group2 flex-col"></div>
                  </div>
                  <span style="font-size:24px;margin:5px 0 0 10px">皮特宠物</span>
                </div>
                <div style="display: flex;">
                  <el-link :underline="false" href="/" style="margin:0 10px 0 0">首页</el-link>
                  <div>
                    <el-avatar :src="userInfo.avatar" size="mini"></el-avatar>
                  </div>
                  <el-badge is-dot class="item" style="margin: 15px 0 0 15px ;">
                    <i class="el-icon-message-solid"></i>
                  </el-badge>

                </div>
              </div>
            </div>
          </div>
        </el-header>
        <el-container>
          <el-aside width="150px">
            <el-menu
              :default-active="this.$route.path"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose">
              <el-menu :default-active="this.$route.path" :router="true" mode="horizontal">
                <el-menu-item v-for="(item,i) in navList" :key="i" :index="item.name">
                  <i :class="item.icon"></i>
                  {{ item.navItem }}
                </el-menu-item>
              </el-menu>
            </el-menu>
          </el-aside>
          <el-main>
            <router-view name="table"></router-view>
          </el-main>
        </el-container>
      </el-container>
    </div>

  </div>
</template>

<script>

export default {
  name: "index",
  data() {
    return {
      navList: [
        { name: "/user/info",icon: "el-icon-delete",navItem: "个人资料" },
        { name: "/user/out",icon: "el-icon-circle-close", navItem: "退出   " },
        { name: "/user/inputMoney", icon: "el-icon-money",navItem: "充值余额" },
        { name: "/user/moneyLog",icon: "el-icon-coin", navItem: "余额日志" }
      ],
      userInfo:{}
    };
  },
  created() {
    let userContent = localStorage.getItem("user");
    this.userInfo = JSON.parse(userContent);
  },
  methods:{
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  },

};
</script>

<style scoped>

</style>
