<template>
  <div class="good-container">
    <h1 class="title-container">麦蒂产品库</h1>

    <div class="table-container">
      <div>
        <div style="float:left;">
          <span>搜索:</span>
          <el-input
            placeholder="产品名称"
            @change="fetchData"
            suffix-icon="el-icon-search"
            size="mini"
            style="width: 300px;margin:0 0 0 10px;"
            v-model="info">
          </el-input>
        </div>
        <el-button
          v-if="isAdmin"
          type="success"
          plain
          style="margin:0 10px 10px 0;float: right"
          size="small"
          @click="addGood"
        >新增
        </el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%;margin-top: 40px;"
        row-key="id"
        border>
        <el-table-column
          prop="id"
          label="序号"
          width="80"
          sortable>
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          sortable>
        </el-table-column>
        <el-table-column
          prop="goods_desc"
          label="产品描述"
          sortable>
        </el-table-column>
        <el-table-column
          prop="state"
          label="商品状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.state === 1" type="success">在售</el-tag>
            <el-tag v-else type="danger">停售</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="created_time"
          label="上架时间"
          sortable
          width="180">
          <template slot-scope="scope">
            {{ scope.row.created_time | formatTime }}
          </template>
        </el-table-column>
        <el-table-column
          prop="rar_name"
          label="图片资源包"
          sortable
          width="180">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" @click="downFile(scope.row)">{{ scope.row.rar_name }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column label="操作" v-if="isAdmin">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="changeState( scope.row)">切换状态
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-drawer
      title="新增下载资源"
      :visible.sync="drawer">
      <el-form ref="form" :model="form" label-width="80px" class="from-container">
        <el-form-item label="产品名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="产品描述">
          <el-input
            v-model="form.goods_desc"
            type="textarea"
            :rows="4"
          ></el-input>
        </el-form-item>
        <el-form-item label="在售状态">
          <div style="float:left;">
            <el-radio v-model="form.state" label="1">在售</el-radio>
            <el-radio v-model="form.state" label="0">停售</el-radio>
          </div>
        </el-form-item>
        <el-form-item label="上架时间">
          <div style="float:left">
            <el-date-picker
              v-model="form.created_time"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="资源路径">
          <el-input v-model="form.images"  :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="下载名称">
          <el-input v-model="form.rar_name"  :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="压缩包">
          <el-upload
            class="upload-demo"
            action="https://index.myhemall.com:12120/file/upload"
            :on-success="handleSuccess"
            multiple
            :limit="3">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">添加</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      info: "",
      isAdmin: false,
      drawer: false,
      form: {
        name: "",
        goods_desc: "",
        state: 0,
        images: "",
        rar_name: "",
        created_time: ""
      },
      tableData: []
    };
  },
  created() {
    const username = this.$store.state.username;
    if (username === "admin") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    this.fetchData();
  },
  methods: {
    onSubmit() {
      let o = this.form;
      o.state = parseInt(o.state);
      this.$axios.post("https://index.myhemall.com:12120/goods/add", o).then(res => {
        if (res.data.errno === 0) {
          this.$message.success(res.data.data);
          this.fetchData();
        }
      });
    },
    fetchData() {
      this.$axios.get("https://index.myhemall.com:12120/goods/list?info=" + this.info).then(res => {
        if (res.data.errno === 0) {
          this.tableData = res.data.data;
        }
      });
    },
    addGood() {
      this.drawer = !this.drawer;
    },
    handleSuccess(res) {
      this.form.images = res.data.file_path;
      this.form.rar_name = res.data.file_name;
    },
    downFile(row) {
      const filePath = row.images;
      const rarName = row.rar_name;
      const url = "https://index.myhemall.com:12120/file/down";
      window.open(url + "?file_path=" + filePath + "&rar_name=" + rarName);
    },
    changeState(row) {
      const id = row.id;
      console.log(id);
      let state = row.state;
      if (state === 1) {
        state = 2;
      } else {
        state = 1;
      }
      this.$axios.get("https://index.myhemall.com:12120/goods/change_state?id=" + id + "&state=" + state).then(res => {
        if (res.data.errno === 0) {
          this.$message.success(res.data.data);
          this.fetchData();
        } else {
          this.$message.error(res.data.errmsg);
        }
      });
    }
  },
  filters: {
    formatTime(val) {
      return moment(val).format("YYYY-MM-DD");
    }
  }
};
</script>

<style scoped>
.title-container {
  margin-top: 10px
}

.good-container {
  margin-top: 30px
}

.table-container {
  padding: 10px
}

.from-container {
  padding: 20px
}
</style>
